import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import { ContactUs } from '../../components/ContactUs/ContactUs'
import '../../components/Style.css'
import { Notice } from '../../components/Notice/Notice'


const NoticesPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <Notice/>
      <ContactUs/>
      <Footer/>
    </div>
  )
}

export default NoticesPage