import React from 'react'
import '../../components/Style.css'
import flogo from '../../assets/flogo.png'

export const Footer = () => {
  return (
    <div class='FooterContainer'>
        <div class="FooterCollection">
                <div class="FooterintroCollection">
                    <img src={flogo} alt="" class="Flogo" />
                    <div class="Fintro">
                        Takshashila Academy is an educational institution on that provides a platform to its pupils for exploring their potentials leading to high quality education.
                    </div>
                </div>
        
                <ul class="Fmenu">
                    <li class="FmenuTitle">Our Programs</li>
                    <li><a  href="/Science" style={{fontWeight: "200"}}>+2 Science Stream</a></li>
                    <li><a  href="/Management" style={{fontWeight: "200"}}>+2 Management Stream</a></li>
                    <li><a  href="/Humanities" style={{fontWeight: "200"}}>Humanities Stream</a></li>
                    <li><a  href="/JuniorWing" style={{fontWeight: "200"}}>Junior Wing</a></li>                    
                </ul>
        
                <ul class="Fmenu">
                    <li class="FmenuTitle">Useful Links</li>
                    <li><a  href="/about" style={{fontWeight: "200"}}>About</a></li>
                    <li><a  href="/ApplyNow" style={{fontWeight: "200"}}>Apply Now</a></li>
                    <li><a  href="/notices" style={{fontWeight: "200"}}>Notices</a></li>
                </ul>
        
                <ul class="Fmenu">
                    <li class="FmenuTitle">Contact Us</li>
                    <li>Samakhushi, Kathmandu, Nepal</li>
                    <li>Phone:<a style={{color: "white" ,fontWeight: "300", href:"tel:014957332"}}> 014957332</a>,<a style={{color: "white" ,fontWeight: "300", href:"tel:014954269"}}> 4954269</a>,<a style={{color: "white" ,fontWeight: "300", href:"tel:014981584"}}> 4981584</a></li>
                    <li>Email:<a style={{color: "white" ,fontWeight: "300"}}> info@tsa.edu.np</a></li>

                </ul>
        </div>
        <div class="FooterCopyright">
          Copyright 2022 Takshashila Academy. All Rights Reserved.
        </div>
    </div>
  )
}
