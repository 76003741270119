import React, { useEffect, useState } from 'react'
import '../../components/Style.css'
import S5 from '../../assets/mobileSlider.png'
import axios from 'axios'

export const SliderMobile = (props) => {
  
  
  return (
    <div class='MobileSliderContainer'>
      <div
        id='carouselExampleIndicators'
        class='carousel slide'
        data-ride='carousel'
      >
        <ol class='carousel-indicators'>
          <li
            data-target='#carouselExampleIndicators'
            data-slide-to='0'
            class='active'
          ></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='1'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='2'></li>
        </ol>
        <div class='carousel-inner'>
          {props.images.map((item, pos) => (
            <div class={pos == 0 ? 'carousel-item active ' : 'carousel-item '}>
              <img
                class='d-block w-100'
                style={{ height: '300px' }}
                src={item.acf.mobileimage}
                alt='First slide'
              />
            </div>
          ))}
          {/* <div class="carousel-item active">
                <img class="d-block w-100" src={S5} alt="First slide" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={S5} alt="Second slide" />
              </div>
              <div class="carousel-item">
                <img class="d-block w-100" src={S5}  alt="Third slide" />
              </div> */}
        </div>
        <a
          class='carousel-control-prev'
          href='#carouselExampleIndicators'
          role='button'
          data-slide='prev'
        >
          <span class='carousel-control-prev-icon' aria-hidden='true'></span>
          <span class='sr-only'>Previous</span>
        </a>
        <a
          class='carousel-control-next'
          href='#carouselExampleIndicators'
          role='button'
          data-slide='next'
        >
          <span class='carousel-control-next-icon' aria-hidden='true'></span>
          <span class='sr-only'>Next</span>
        </a>
      </div>
    </div>
  )
}
