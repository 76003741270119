import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { AdmissionForm } from '../../components/AdmissionForm/AdmissionForm'

const ApplyNowPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <AdmissionForm/>     
      <Footer/>
    </div>
  )
}

export default ApplyNowPage