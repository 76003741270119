import React from 'react';
import logo from './logo.svg';
import './App.css';
import HomePage from './Pages/HomePage/HomePage';
import AboutUsPage from './Pages/AboutUsPage/AboutUsPage';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import OurProgramsPage from './Pages/OurPrograms/OurPrograms';
import NoticesPage from './Pages/NoticesPage/NoticesPage';
import ApplyNowPage from './Pages/ApplyNowPage/ApplyNowPage';
import { SingleNotice } from './components/Notice/SingleNotice';
import MessageDetailPage from './Pages/MessageDetailPage/MessageDetailPage';
import SciencePage from './Pages/SciencePage/SciencePage';
import ManagementPage from './Pages/ManagementPage/ManagementPage';
import HumanitiesPage from './Pages/HumanitiesPage/HumanitiesPage';
import JuniorWingp from './Pages/JuniorWingp/JuniorWingp';
import LawPage from './Pages/Law/LawPage';
import BBSPage from './Pages/BBSPage/BBSPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path='/' element={<HomePage />}>
          {' '}
        </Route>
        <Route exact path='/about' element={<AboutUsPage />}></Route>
        <Route exact path='/programs' element={<OurProgramsPage />}></Route>
        <Route exact path='/noticePage' element={<NoticesPage />}></Route>
        <Route exact path='/ApplyNow' element={<ApplyNowPage />}></Route>
        <Route exact path='/notice/:title' element={<SingleNotice />}></Route>
        <Route exact path='/Science' element={<SciencePage />}></Route>
        <Route exact path='/MsgDtl' element={<MessageDetailPage />}></Route>
        <Route exact path='/Management' element={<ManagementPage />}></Route>
        <Route exact path='/Humanities' element={<HumanitiesPage />}></Route>
        <Route exact path='/JuniorWing' element={<JuniorWingp />}></Route>
        <Route exact path='/notice' element={<SingleNotice />}></Route>
        <Route exact path='/LawDetails' element={<LawPage />}></Route>
        <Route exact path='/BBSPage' element={<BBSPage />}></Route>

      </Routes>
    </BrowserRouter>
  )
}

export default App
