import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import { Mission } from '../../components/Mission/Mission'
import { ContactUs } from '../../components/ContactUs/ContactUs'
import '../../components/Style.css'
import { TitleDescription } from '../../components/TitleDescription/TitleDescription'
import { SingleImge } from '../../components/SingleImge/SingleImge'
import { TakMean } from '../../components/TakMean/TakMean'
import { Bod } from '../../components/Bod/Bod'
import { OurPrograms } from '../../components/Programs/Programs'

const OurProgramsPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <OurPrograms/>
      <ContactUs/>
      <Footer/>
    </div>
  )
}

export default OurProgramsPage