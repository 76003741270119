import React from 'react'
import '../../components/Style.css'
import BodImg7 from '../../assets/Bod7.png'
import SC1 from '../../assets/SC1.png'
import SC2 from '../../assets/SC2.png'
import SC3 from '../../assets/Sc3.png'
import SC4 from '../../assets/SC4.png'


export const ScienceDetailContainer = () => {
return (
    <div className="DetailContainer">
     <div className="DetailIntroContainer">
            <div className="DetailIntro">
                  <div className="DetailTitle">Science +2</div>
                  <div class="DetailPageDescription">The Faculty of Science at TSA has primary goal to provide advanced and pragmatic in-depth knowledge of science to produce high caliber science scholars. It aims to create foundation for future doctors, engineers, agriculturist, forest officials and scientist. To meet its goal TSA provides the best and quality education which assists to visualize true potentials of students in various fields of science. It was established by reputed and professional academicians in the field of Science who are committed to impart a need-based quality education so as to ensure academic par excellence and attain outstanding result in board exam. Our proficient and professional teachers deliver theory lectures along with practical demonstration in our sophisticated and well equipped modern laboratory to retain practical know-how to the students and strengthen their understanding.</div>
            </div>
      </div>

    
      <div class="subjectSlider">
              <br/>
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img class="d-block w-100" src={SC1} alt="First slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC2} alt="Second slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC3} alt="Third slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC4} alt="Third slide"/>
                          </div>

                          
                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>

                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                  </div>
              <br/>
      </div> 

      <div className="imagescroll">
            <img className="DetailImage" src={SC1} srcset="" />
            <img className="DetailImage" src={SC2} srcset="" />
            <img className="DetailImage" src={SC3} srcset="" />
            <img className="DetailImage" src={SC4} srcset="" />
      </div> 

      <div class="DetailPageDescription2">Takshashila Academy follows the course of study set by the National Examination Board (NEB). The courses are the following:</div>
      <div className="stream">
      <div class="DetailPageDescription2">
              <b>Class XI : Science stream</b><br/>
              1. English <br/>
              2. Nepali<br/>
              3. Mathematics<br/> 
              4. Physics <br/>
              5. Chemistry<br/>
              6. Biology/Computer Science<br/>
              </div>

              <div class="DetailPageDescription2">
              <b>Class XII : Science stream</b><br/>
              1. English <br/>
              2. Nepali<br/>
              3. Mathematics<br/> 
              4. Physics <br/>
              5. Chemistry<br/>
              6. Biology/Computer Science<br/>
             </div>
      </div>



      <div  id="4" className="SingleStaffMsg">
                <div className="IntroImage">
                    <div className="singleIntro">
                        <div class="MsgNameHolder" style={{ 'color':'#000000'}}>Mr. Naresh Prasad Bhatt - Program Director</div>
                        <div class="SingleMsgTitle">“Education is the base for development. It is a precious jewel to adorn life with beauty, perfection and boundless opportunities"</div>
                        <div class="SingleMsgDescription" >We hope that students will be sensible enough in the correct choice of institution for further learning. Your association with us will surely give you higher perfection, satisfaction and security. At Takshashila, you can be sure about being a part of distinctly novel academic arena with an exceptionally sound environment. So, bestow us the pleasure of welcoming you to this wonderful world of learning and career building.</div>
                    </div>
                    <img class="SingleMsgImage" src={BodImg7} alt=""/>
                </div>
                {/* <div class="SingleMsgLongDescription">to nurture the future for every family we serve. I believe that when you read our prospectus, you will be impressed by our desire to nurture; to care for, encourage and support your children. I hope, however, you will also be struck by our determination to develop their gifts to help them achieve their full potential. We aim to provide a holistic learning experience by encouraging individuality and interacting with our community. Please enjoy learning more about our institution, through this prospectus. I hope you will give Takshashila, the opportunity to prepare your child for a happy and successful future.</div> */}
                {/* <div className="singleMsgdivider"></div> */}
      </div>


    </div>
  )
}
