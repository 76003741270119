import React from 'react'
import '../../components/Style.css'
import BodImg12 from '../../assets/Bod12.png'
import SC1 from '../../assets/SC1.png'
import SC2 from '../../assets/SC2.png'
import SC3 from '../../assets/Sc3.png'
import SC4 from '../../assets/SC4.png'


export const JuniorWing = () => {
return (
    <div className="DetailContainer">
     <div className="DetailIntroContainer">
            <div className="DetailIntro">
                  <div className="DetailTitle">Junior Wing</div>
                  <div class="DetailPageDescription">TSA School is committed to academic excellence. The school is dedicated to maintain the academic environment with the help of outstanding faculty engaged in the task of creative sensibility, or sense of responsibility and moral integrity.
                  TSA has a qualified team of instructors, who are continuously supervised and guided by a group of academicians, social workers as well as people involved in different disciplines of society. We have innovative practice in teaching and learning which can help students prove themselves outstanding in every corner of the world.</div>
            </div>
      </div>

{/*     
      <div class="subjectSlider">
              <br/>
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img class="d-block w-100" src={SC1} alt="First slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC2} alt="Second slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC3} alt="Third slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC4} alt="Third slide"/>
                          </div>

                          
                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>

                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                  </div>
              <br/>
      </div> 

      <div className="imagescroll">
            <img className="DetailImage" src={SC1} srcset="" />
            <img className="DetailImage" src={SC2} srcset="" />
            <img className="DetailImage" src={SC3} srcset="" />
            <img className="DetailImage" src={SC4} srcset="" />
      </div>  */}

      {/* <div class="DetailPageDescription2">Takshashila Academy follows the course of study set by the National Examination Board (NEB). The courses are the following:</div>
      <div className="stream">
              <div class="DetailPageDescription2">
              <b>Class XI : Humanities Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory Nepali <br/>
              3. Social<br/>
              3. Optional Subjects (Any Three) <br/>
              (i) Major English <br/>
              (ii) Mass Communication <br/>
              (iii) Sociology <br/>
              (iv) Economics <br/>
              (v) Mathematics<br/>
              (vi) Rural Development<br/>
              </div>

              <div class="DetailPageDescription2">
              <b>Class XI : Humanities Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory Nepali <br/>
              3. Social<br/>
              3. Optional Subjects (Any Three) <br/>
              (i) Major English <br/>
              (ii) Mass Communication <br/>
              (iii) Sociology <br/>
              (iv) Economics <br/>
              (v) Mathematics<br/>
              (vi) Rural Development<br/>
              </div>
      </div> */}



      <div  id="4" className="SingleStaffMsg">
                <div className="IntroImage">
                    <div className="singleIntro">
                        <div class="MsgNameHolder" style={{ 'color':'#000000'}}>Mr. Modaraj Pandey - School Coordinator</div>
                        <div class="SingleMsgTitle">“Making a choice of proper educational centre for learning can prove to be a turning point in one’s career and life.”</div>
                        <div class="SingleMsgDescription" >For this, the aspiring students must use their discretion in selecting the institution which will provide right nurture in all round development. This is to make the students competent in this fast growing world of competition and understand the ways to tackle with complexities. It is a way of attaining completeness in ourself.</div>
                    </div>
                    <img class="SingleMsgImage" src={BodImg12} alt=""/>
                </div>
                {/* <div class="SingleMsgLongDescription">to nurture the future for every family we serve. I believe that when you read our prospectus, you will be impressed by our desire to nurture; to care for, encourage and support your children. I hope, however, you will also be struck by our determination to develop their gifts to help them achieve their full potential. We aim to provide a holistic learning experience by encouraging individuality and interacting with our community. Please enjoy learning more about our institution, through this prospectus. I hope you will give Takshashila, the opportunity to prepare your child for a happy and successful future.</div> */}
                {/* <div className="singleMsgdivider"></div> */}
      </div>


    </div>
  )
}
