import React from 'react'
import '../../components/Style.css'

export const TitleDescription = () => {
  return (
    <div class="AboutDescriptionContainer">
        <div class="MainTitle" style={{ 'paddingLeft':'20px'}}>About Takshashila Academy</div>
        <div class="LongDescription">Takshashila Academy is an educational institution on that provides a platform to its pupils for exploring their potentials leading to high quality education. It was established in 1998. Takshashila University was a center of learning even before Lord Buddha. It was the University where Panini wrote the Sanskrit grammar called Ashtadhyayi which is regarded as the best grammar of any language written so far in the world. Takshashila University was the one where Mahayana Buddhism was studied and Kautilaya studied, taught and wrote his famous book Arthashastra (Economics). At Takshashila Academy, we provide best teaching – learning environment which helps to foster the quality education through highly qualified academicians of Nepal and abroad. We are determined to have the best possible teachers and students to create a vibrant academic atmosphere and to promote scientific and philosophical inquiry supporting research in all the fi elds of knowledge through rigorous work. We are planning to upgrade this educational institution up to the university level and also collaborate with foreign universities.</div>
    </div>
  )
}
