import React from 'react'
import '../../components/Style.css'
import SingleImage from '../../assets/takshala.png'


export const SingleImge = () => {
  return (
    <img Class="TakshalaImage" src={SingleImage} alt="" srcset="" />
  ) 
}
