import React from 'react'
import '../../components/Style.css'
import MGH from '../../assets/MGHEAD.png'
import MGM1 from '../../assets/MGM1.png'
import MGM2 from '../../assets/MGM2.png'
import MGM3 from '../../assets/MGM3.png'
import MGM4 from '../../assets/MGM4.png'
import MGM5 from '../../assets/MGM5.png'
import MGM6 from '../../assets/MGM6.png'
import MGM7 from '../../assets/MGM7.png'

export const ManagementDetailContainer = () => {
return (
    <div className="DetailContainer">
     <div className="DetailIntroContainer">
            <div className="DetailIntro">
                  <div className="DetailTitle">Management +2</div>
                  <div class="DetailPageDescription">Faculty of Management in TSA has been initiated since its establishment. The primary goal of Faculty of Management is to provide in-depth knowledge of management in its level. It aims to produce future managers and chartered accountants. It was established by reputed and professional academicians in the field of management. It provides practical base knowledge to students. It not only focuses National Examination Board syllabus but also focuses on competitive examination of CA and BBA courses. It conducts extra classes of research and presentation to make students competent and confidence. As a result, a number of students have been performing better in the field of research, and other examinations conducted by respective universities. Besides academic excellence, TSA conducts a number of ECA programs such as sports, quiz so as to explore and promote the students hidden talents. In order to make the teaching learning process more practical and effective, TSA organizes frequent industrial and field visits and excursions as well. Similarly, the college encourages the students to take part in researches and presentations so that they could express themselves.</div>
            </div>
      </div>

    
      <div class="subjectSlider">
              <br/>
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img class="d-block w-100" src={MGM1} alt="First slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={MGM2} alt="Second slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={MGM3} alt="Third slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={MGM4} alt="Third slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={MGM5} alt="Third slide"/>
                          </div>   
                          <div class="carousel-item">
                              <img class="d-block w-100" src={MGM6} alt="Third slide"/>
                          </div>                       
                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>

                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                  </div>
              <br/>
      </div> 

      <div className="imagescroll">
            <img className="DetailImage" src={MGM1} srcset="" />
            <img className="DetailImage" src={MGM2} srcset="" />
            <img className="DetailImage" src={MGM3} srcset="" />
            <img className="DetailImage" src={MGM4} srcset="" />
            <img className="DetailImage" src={MGM5} srcset="" />
            <img className="DetailImage" src={MGM6} srcset="" />
            <img className="DetailImage" src={MGM7} srcset="" />
      </div> 

      <div class="DetailPageDescription2">Takshashila Academy follows the course of study set by the National Examination Board (NEB). The courses are the following:</div>
      <div className="stream">
      <div class="DetailPageDescription2">
              <b>Class XI : Management Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory English<br/>
              3. Social/Mathmatics<br/> 
              4. Optional Subjects (Any Three) <br/>
              (i)Accountancy <br/>
              (ii) Economics <br/>
              (iii) Business Studies/Business Mathematics<br/>
              (iv) Computer Science<br/>
              (v) Hotel Management <br/>
              (vi) Travelling and Mountaineering<br/>
              (vii) Marketing<br/>
              </div>

              <div class="DetailPageDescription2">
              <b>Class XII : Management Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory Nepali<br/>
              3. Social/Mathmatics<br/>
              4. Optional Subjects (Any Three) <br/>
              (i) Accountancy <br/>
              (ii) Economics <br/>
              (iii) Business Studies/Business Mathematics<br/>
              (iv) Computer Science<br/>
              (v) Hotel Management <br/>
              (vi) Travelling and Mountaineering<br/>
              (vii) Marketing<br/>
             </div>
      </div>



      <div  id="4" className="SingleStaffMsg">
                <div className="IntroImage">
                    <div className="singleIntro">
                        <div class="MsgNameHolder" style={{ 'color':'#000000'}}>Mr. JP Sitaula - Program Director</div>
                        <div class="SingleMsgTitle">“With the glorious name Takshashila Academy, we are moving forward with a sense of determination to build an educational institution of excellence.”</div>
                        <div class="SingleMsgDescription" >High quality is our goal. We do not want to compromise on it. We are confident that with our sincere efforts to teach, students will definitely learn something good for their life. Already there have been our students in medical sciences, engineering, business enterprises and humanities as illustrious examples. I would like to urge the parents to bring their children to Takshashila for their education with a good amount of moral content and emphasis on the strength of character in addition to the quality of education.  I am sure the society around us will feel proud that there is such an institution like ours.</div>
                    </div>
                    <img class="SingleMsgImage" src={MGH} alt=""/>
                </div>
                {/* <div class="SingleMsgLongDescription">to nurture the future for every family we serve. I believe that when you read our prospectus, you will be impressed by our desire to nurture; to care for, encourage and support your children. I hope, however, you will also be struck by our determination to develop their gifts to help them achieve their full potential. We aim to provide a holistic learning experience by encouraging individuality and interacting with our community. Please enjoy learning more about our institution, through this prospectus. I hope you will give Takshashila, the opportunity to prepare your child for a happy and successful future.</div> */}
                {/* <div className="singleMsgdivider"></div> */}
      </div>


    </div>
  )
}
