import React, { useState } from 'react'
import '../../components/Style.css'
import logo from '../../assets/logo.png'
import styled from 'styled-components'
import { Menu, X } from 'react-feather'
import { mobile } from '../../responsive'

const StyledMenu = styled.nav`
  display: flex;
  width: 100%;
  background-color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transform: ${({ open }) => (open ? 'translateY(0)' : 'translateY(-200%)')};

  /* text-align: center; */
  padding: 2rem;

  position: fixed;
  top: 10;
  /* left: 50
  transition: transform 0.3s ease-in-out;
  /* z-index: 11; */

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 16px;
    color: black;
    width: 100%;
    padding: 0.5rem 0;
    font-weight: bold;
    text-align: center;

    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 18px;
      text-align: center;
    }

    &:hover {
      color: white;
      background-color: black;
      cursor: pointer;
    }
  }
`
const HamBurgerMenu = styled.div`
  padding: 10px;
  display: none;
  &:hover {
    background-color: ${(props) => (props.open ? 'black' : 'white')};
    color: ${(props) => (props.open ? 'white' : 'black')};
  }
  ${mobile({
    display: 'flex',
  })}
`

export const NavBar = () => {
  const [open, setOpen] = useState()
  return (
    <div class='NavContainer'>
      <div class='MenuElements'>
        <a href='/#'>
          <div className='logoContainer'>
            <img class='logo' src={logo} alt='' />
          </div>
        </a>
        <div class='MenuOption'>
          <a class='Menuli' href='/About'>
            {' '}
            About Us{' '}
          </a>
          <a class='Menuli' href='/programs'>
            {' '}
            Our Programs{' '}
          </a>
          <a class='Menuli' href='/noticePage'>
            {' '}
            Notices{' '}
          </a>
          <a class='Btn1' href='/ApplyNow'>
            {' '}
            Apply Now{' '}
          </a>
        </div>
        <HamBurgerMenu
          open={open}
          onClick={() => {
            setOpen(!open)
            console.log('open: ' + open)
          }}
        >
          <Menu style={{ display: open ? 'none' : 'flex' }} />
          <X style={{ display: open ? 'flex' : 'none' }} />
        </HamBurgerMenu>
      </div>

      <StyledMenu open={open} style={{ fontFamily: 'Josefin Sans' }}>
        <a href='/About'>
          {' '}
          About Us{' '}
        </a>
        <a  href='/programs'>
          {' '}
          Our Programs{' '}
        </a>
        <a  href='/noticePage'>
          {' '}
          Notices{' '}
        </a>
        <a  href='/ApplyNow'>
          {' '}
          Apply Now{' '}
        </a>
      </StyledMenu>
    </div>
  )
}
