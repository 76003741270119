import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { MessageDetailContainer } from '../../components/MessageDetailContainer/MessageDetailContainer'

const MessageDetailPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <MessageDetailContainer/>
      <Footer/>
    </div>
  )
}

export default MessageDetailPage