import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../Base'
import '../../components/Style.css'
import { setActiveImage } from '../../redux/sliderRedux'
import { convertMonth } from '../../utils/convertDate'

export const PrograAndNoitce = () => {
    const dispatch = useDispatch()
    const [Items, setItems] = useState([])
    useEffect(() => {
      const getProducts = async () => {
        try {
          const res = await axios.get(
            BASE_URL+'/wp-json/wp/v2/notice?acf_format=standard&per_page=6'
          )
          setItems(res.data)
        } catch (err) {}
      }
      getProducts()
    }, [])

    
  return (
    <div class='MainPNContainer'>
      <div class='PNcontainer'>
        <div class='Program'>
          <div class='MainTitle'>OUR PROGRAMS</div>
          <div class='ProgramContainer'>
            <div class='InfoWrapper'>
              <div class='InfoTitle' style={{ color: '#89CC59' }}>
                Junior Level Program
              </div>
              <div class='InfoDescription'>
              Takshashila Academy has its junior wing where we teach children from Nursery to Grade Ten as well. 
              </div>
            </div>

            <div class='InfoWrapper'>
              <div class='InfoTitle' style={{ color: '#89CC59' }}>
                +2 Science Programs
              </div>
              <div class='InfoDescription'>
              Estalished to provide advanced and pragmatic in-depth knowledge of science to produce high caliber science scholars.
              </div>
            </div>

            <div class='InfoWrapper'>
              <div class='InfoTitle' style={{ color: '#89CC59' }}>
                +2 Management Programs
              </div>
              <div class='InfoDescription'>
              Estalished to provide in-depth knowledge of management in its level. It aims to produce future managers and chartered accountants.
              </div>
            </div>

            <div class='InfoWrapper'>
              <div class='InfoTitle' style={{ color: '#89CC59' }}>
                Bachelor Programs
              </div>
              <div class='InfoDescription'>
              Estalished four years BBS Program since 2013 with highly qualified and experienced academicians. The gate way in TSA to get the higher study is academically sound and universally recognized. 
              </div>
            </div>
          </div>
          <a href='/programs' class='Btn2'>
            View All program
          </a>
        </div>

        <div class='Notice'>
          <div class='MainTitle'>LATEST NOTICES</div>
          <div class='NoticeContainer'>
            {Items.map((item, pos) => (
              <div class='Noticewrrapper'>
                <div class='NoticeBullet'></div>
                 <Link
                  class='NoticeTitle'
                  to={'/notice'}
                  // to={`/notice/${item.title}`}
                  onClick={()=>{
                    dispatch(setActiveImage(item.acf.image))
                  }}
                  target="_blank"
                > 
                 
                  {item.acf.title}
                </Link>
                <div class='NoticeDate'>
                 
                  {convertMonth(
                    item.date.substring(0, 10).split('-')[1]
                  ) +
                    ' ' +
                    item.date.substring(0, 10).split('-')[2] +
                    ' ' +
                    item.date.substring(0, 10).split('-')[0]}
                </div>
              </div>
             ))} 
           
          </div>
          <a href='/noticePage' class='Btn2'>
            View All Notice
          </a>
        </div>
      </div>
    </div>
  )
}
