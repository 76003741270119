import React from 'react'
import '../../components/Style.css'
import Msg1 from '../../assets/Msg1.png'
import Msg2 from '../../assets/Msg2.png'
import Msg3 from '../../assets/Msg3.png'
import Msg4 from '../../assets/Msg4.png'


export const MsgRight = () => {
  return (
    
        <div>
        <div class="MsgContainer1">
                <div class="MsgInfo">
                    <div class="MsgNameHolder">Netra Prasad Neupane - Principal</div>
                    <div class="MsgTitle">“Education is a Shared Commitment Between Dedicated Teachers, Motivated Student & Enthusiastic Parents with high Expectation”</div>
                    <div class="MsgDescription">Today, the role of an Educational institution is not only to pursue academic excellence but also to mo vate and empower its students to be lifelong learners, cri cal thinkers, and produc ve members of an ever-changing global society. More than two decades back Takshashila pledged to transform educa on. The ins tu on that is being run under the aegis of well established management has earned its own niche not only in Kathmandu but across the country. At Takshashila we provide an atmosphere to our students for mul faceted development, where students are encouraged to channelize their poten al in the pursuit of excellence.</div>
                    <a href="/MsgDtl#1" class="Btn3">Read More</a>          
                </div>
                <img class="MsgImage" src={Msg1} alt=""/>
            </div>

            <div class="MsgContainer2">
            <img class="MsgImage" src={Msg2} alt=""/>
                <div class="MsgInfo">
                    <div class="MsgNameHolder">Dr. Jayanta Raj Acharya - Chairman</div>
                    <div class="MsgTitle">“Educational institution are described as the future within four walls. That being true, we have the responsibility to make the most exciting, creative and secure places we can”</div>
                    <div class="MsgDescription">As the Chairperson of Takshashila Management Committee, I know that our children are our precious gift and they are the future too. Takshashila therefore is committed to provide an outstanding start for the education of the students. Our mission is to serve the humanity at largest ensuring to nurture the future for every family we serve.I believe that when you read our prospectus, you will be impressed by our desire to nurture; to care for, encourage and support your children</div>
                    <a href="/MsgDtl#2" class="Btn3">Read More</a>          
                </div>
            </div>

            <div class="MsgContainer3">
                <div class="MsgInfo">
                    <div class="MsgNameHolder">Dr. Bishan Datt Bhatt - Chief Academic Advisor </div>
                    <div class="MsgTitle">“Education is a Shared Commitment Between Dedicated Teachers, Motivated Student & Enthusiastic Parents with high Expectation”</div>
                    <div class="MsgDescription">'I feel honored to extend my hear  est welcome to you in Takshashila Academy. I welcome you to be a part of our mission of bringing-up academically sound, socially responsible and morally upright citizens of Nepal. Quality education fosters all round personally development in the students, with enables them to meet the challenges in their lives. With a team of highly experienced educationists of Nepal, Takshashila Academy is capable of fulfilling the needs of Nepal in the years to come. </div>
                    <a href="/MsgDtl#3" class="Btn3">Read More</a>          
                </div>
                <img class="MsgImage" src={Msg3} alt=""/>
            </div>

            <div class="MsgContainer2">
            <img class="MsgImage" src={Msg4} alt=""/>
                <div class="MsgInfo">
                    <div class="MsgNameHolder">Mr. Bharat Aryal- Administrative Advisor</div>
                    <div class="MsgTitle">“With the glorious name Takshashila Academy, we are moving forward with a sense of determination to build an educational institution of excellence.”</div>
                    <div class="MsgDescription">High quality is our goal. We do not want to compromise on it. We are confident that with our sincere efforts to teach, students will definitely learn something good for their life. Already there have been our students in medical sciences, engineering, business enterprises and humanities as illustrious examples. I would like to urge the parents to bring their children to Takshashila for their education with a good amount of moral content and emphasis on the strength of character in addition to the quality of education.</div>
                    <a href="/MsgDtl#4" class="Btn3">Read More</a>          
                </div>
            </div>
    
    </div>
    
  )
}
