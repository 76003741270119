import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { LawDetails } from '../../components/LawDetails/LawDetails'

const LawPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <LawDetails/>
      <Footer/>
    </div>
  )
}

export default LawPage