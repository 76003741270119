import { createSlice } from "@reduxjs/toolkit";

const sliderSlice = createSlice({
  name: 'slider',
  initialState: {
    activeImage:'',
    
  },
  reducers: {
    setActiveImage: (state, action) => {
      state.activeImage = action.payload
    },
  },
})

export const {
  setActiveImage
} = sliderSlice.actions

export default sliderSlice.reducer