import React, { useEffect } from 'react'
import '../../components/Style.css'
import axios from 'axios'
import { useState } from 'react'
import S5 from '../../assets/mobileSlider.png'
import S1 from '../../assets/s1.png'
import S2 from '../../assets/s2.png'
import S3 from '../../assets/s3.png'
import { current } from '@reduxjs/toolkit'


export const SliderDesktop = (props) => {
  const [Items, setItems] = useState([])
  const [Images,setImages] = useState([])

  return (
    <div class='DesktopSliderContainer'>
      <div
        id='carouselExampleIndicators'
        class='carousel slide'
        data-ride='carousel'
      >
        <ol class='carousel-indicators'>
          <li
            data-target='#carouselExampleIndicators'
            data-slide-to='0'
            class='active'
          ></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='1'></li>
          <li data-target='#carouselExampleIndicators' data-slide-to='2'></li>
        </ol>
        <div class='carousel-inner'>
          {props.images.map((item, pos) => (
            <div class={pos == 0 ? 'carousel-item active ' : 'carousel-item '}>
              <img
                class='d-block w-100'
                style={{ height: '300px' }}
                src={item.acf.desktopimage}
                alt='First slide'
              />
            </div>
          ))}

          {/* <div class='carousel-item active'>
            <img class='d-block w-100' src={S1} alt='First slide' />
          </div>
          <div class='carousel-item'>
            <img class='d-block w-100' src={S2} alt='Second slide' />
          </div>
          <div class='carousel-item'>
            <img class='d-block w-100' src={S3} alt='Third slide' />
          </div> */}
        </div>
        <a
          class='carousel-control-prev'
          href='#carouselExampleIndicators'
          role='button'
          data-slide='prev'
        >
          <span class='carousel-control-prev-icon' aria-hidden='true'></span>
          <span class='sr-only'>Previous</span>
        </a>
        <a
          class='carousel-control-next'
          href='#carouselExampleIndicators'
          role='button'
          data-slide='next'
        >
          <span class='carousel-control-next-icon' aria-hidden='true'></span>
          <span class='sr-only'>Next</span>
        </a>
      </div>
    </div>
  )
}
