import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Slider, SliderDesktop } from '../../components/SliderDesktop/SliderDesktop'
import { Footer } from '../../components/Footer/Footer'
import { TextSlider } from '../../components/TexTslider/TextSlider'
import { Mission } from '../../components/Mission/Mission'
import { PrograAndNoitce } from '../../components/ProgramAndNotice/ProgramAndNotice'
import { MsgRight } from '../../components/MsgRight/MsgRight'
import { ContactUs } from '../../components/ContactUs/ContactUs'
import '../../components/Style.css'
import { SliderMobile } from '../../components/SliderMobile/SliderMobile'
import { useState } from 'react'
import { useEffect } from 'react'
import axios from 'axios'
import { BASE_URL } from '../../Base'
import { RegistrationOpen, RegistrationOpenn } from '../../components/RegistrationOpen/RegistrationOpen'
import { Popup } from '../../components/popup/Popup'




const HomePage = () => {
   const [open, setOpen] = useState(true)
   const [Items, setItems] = useState([])
   const [mobileImages, setMobileImages] = useState([])
   const [desktopImages, setDeskTopImages] = useState([])
   const [popup, setPopup] = useState([])

   const openHandler = (val)=>{
    setOpen(val)
   }
 
  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await axios.get(
          BASE_URL+'/wp-json/wp/v2/slider?acf_format=standard'
        )
        setItems(res.data)
      } catch (err) {}
    }
    getProducts()
  }, [])

  
  useEffect(() => {
    const getPopup = async () => {
      try {
        const res = await axios.get(
          BASE_URL + '/wp-json/wp/v2/popup?acf_format=standard'
        )
        setPopup(res.data)
        console.log(res.data)
      } catch (err) {}
    }
    getPopup()
  }, [])

  

  var activeComponent
  if (window.innerWidth >= 825) {
    activeComponent = <SliderDesktop images = {Items} />
  } else if (window.innerWidth < 825) {
    activeComponent = <SliderMobile images = {Items} />
  }


  return (
    <div>
      {popup.length > 0 && (
        <Popup display={open ? 'block' : 'none'} popup={openHandler} image = {popup[0].acf.image}/>
      )}
      <div className='pageContainer' style={{ display: popup.length>0&&open ? 'none' : '' } }>
        <NavBar />
        {activeComponent}

        <TextSlider />
        <RegistrationOpen />
        <Mission />
        <PrograAndNoitce />
        <MsgRight />
        <ContactUs />
        <Footer />
      </div>
    </div>
  )
}

export default HomePage