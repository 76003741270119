import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { JuniorWing } from '../../components/JuniorWing/JuniorWing'

const JuniorWingp = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <JuniorWing/>
      <Footer/>
    </div>
  )
}

export default JuniorWingp