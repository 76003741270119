import axios from 'axios'
import React, { useState } from 'react'
import '../../components/Style.css'

export const ContactUs = () => {
    const [data,setData] = useState({
        // name:null,
        // email:null,
        // contact:null,
        // message:null

    })
    const myRequest = (url) => {
      let response
      try {
        response = axios.post(url, null, null)
        alert("Submitted Successfully. we will contact you soon")

        //   headers: {
        //     'Content-Type': 'text/plain',
        //     'Accept': 'text/plain',
        //   },
        // })
      } catch (e) {
        response = e
        console.log(e)
        
      }
     
    }

    const handleChange=(e)=>{
        e.preventDefault();
        const { name, value,id } = e.target
        console.log(e.target)
        setData({...data,[name]:value})
        localStorage.setItem('target',id)
        localStorage.setItem('data',JSON.stringify(data))
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        const id = '1dsUd8NiBFyr8ds6XO7pMTfUdN3icgCdSaUZsXZk-92g'
        const formUrl =
          'https://docs.google.com/forms/d/' + id + '/formResponse'
        const queryString = require('query-string')
        const q = queryString.stringifyUrl({
          url: formUrl,
          query: data,
        })
        myRequest(q)
        setData({
          'entry.664465022': '',
          'entry.441960010': '',
          'entry.577401749': '',
          'entry.984191673': '',
        })
        
    }
    
  return (
    <div class='ContactUsContainer'>
      <div class='ContactFormContainer'>
        <div class='MainTitle'>Contact us Now</div>
        <form
          onSubmit={handleSubmit}
          //   method='POST'
          //   action='https://docs.google.com/forms/d/1dsUd8NiBFyr8ds6XO7pMTfUdN3icgCdSaUZsXZk-92g/formResponse'
        >
          <div class='inputbox'>
            <input
              type='text'
              id='username'
              //   name='username'
              name='entry.664465022'
              placeholder='Enter Your Full Name'
              onChange={handleChange}
              value={data['entry.664465022']}
              contentEditable={true}
              required
            />
            <label>
              <span>Your Full Name</span>
            </label>
          </div>

          <div class='inputbox'>
            <input
              type='text'
              id='contact'
              //   name='username'
              name='entry.441960010'
              placeholder='Enter Your Contact Number'
              onChange={handleChange}
              contentEditable={true}
              value={data['entry.441960010']}
              readOnly={false}
              required
            />
            <label>
              <span> Your Contact Number</span>
            </label>
          </div>

          <div class='inputbox'>
            <input
              type='text'
              id='username'
              //   name='username'
              name='entry.577401749'
              placeholder='Enter Your Email Address'
              onChange={handleChange}
              contentEditable={true}
              value={data['entry.577401749']}
              required
            />
            <label>
              <span> Your Email Address</span>
            </label>
          </div>

          <div class='inputbox'>
            <input
              type='text'
              id='username'
              //   name='username'
              name='entry.984191673'
              placeholder='Enter Your Message'
              onChange={handleChange}
              contentEditable={true}
              value={data['entry.984191673']}
              required
            />
            <label>
              <span> Your Message</span>
            </label>
          </div>
          <button class='Btn4' type='submit'>
            Send Message
          </button>
        </form>
      </div>
    </div>
  )
}

