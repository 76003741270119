import React from 'react'
import '../../components/Style.css'
import SCI from '../../assets/SCI.png'
import MG1 from '../../assets/MG.png'
import HU from '../../assets/HU.png'
import JW from '../../assets/JW.png'
import LAW from '../../assets/law.png'
import BBS from '../../assets/BBS.png'


import BodImg7 from '../../assets/Bod7.png'






export const OurPrograms = () => {
  return (
    <div class="programContainer">
        <div class="AboutDescriptionContainer2">
            <div class="MainTitle">OUR PROGRAMS</div>
            <div class="LongDescription">Takshashila has been accredited and certified By National Examination Board(NEB).
            it is approved to offer courses in Science, management, Humanities, and Law.
            We are determined to have the best possible teachers and students to create a vibrant academic atmosphere and to
promote scientific and philosophical inquiry supporting research in all the fields of knowledge through rigorous work. We are planning to upgrade this educational institution up to the university level and also collaborate with foreign universities.</div>
        </div>
        
        <div class="ProgramBoxContainer">
            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/Science"><img class="ProggramImage" src={SCI} alt=""/>
                <div class="Programtitle">+2 Science Stream</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>The Faculty of Science at TSA has primary goal to provide advanced and pragmatic in-depth</div>
                <a class="LearnMoreCTA" href="/Science">Learn More</a></a>
            </div>

            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/Management"><img class="ProggramImage" src={MG1} alt=""/>
                <div class="Programtitle">+2 Management Stream</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>The primary goal of Faculty of Management is to provide in-depth knowledge of management in its level</div>
                <a class="LearnMoreCTA" href="/Management">Learn More</a></a>
            </div>

            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/Humanities"><img class="ProggramImage" src={HU} alt=""/>
                <div class="Programtitle">Humanities Stream</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>Humanities stream has a wider horizon than any other subjects that give you a plethora of career options</div>
                <a class="LearnMoreCTA" href="/Humanities">Learn More</a></a>
            </div>

            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/JuniorWing"><img class="ProggramImage" src={JW} alt=""/>
                <div class="Programtitle">Junior Wing</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>The school is dedicated to maintain the academic environment with the help of outstanding faculty engaged</div>
                <a class="LearnMoreCTA" href="/JuniorWing">Learn More</a></a>
            </div>

            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/LawDetails"><img class="ProggramImage" src={LAW} alt=""/>
                <div class="Programtitle">Law</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>The primary goal of the Faculty of Law to provide in-depth knowledge of law in its level.</div>
                <a class="LearnMoreCTA" href="/LawDetails">Learn More</a></a>
            </div>

            <div class="ProgramItems">
                <a class="LearnMoreCTA" style={{ 'padding':'0px'}} href="/BBSPage"><img class="ProggramImage" src={BBS} alt=""/>
                <div class="Programtitle">BBS (Business Studies)</div>
                <div class="ProgramInfo" style={{ 'color':'#000000', 'fontWeight':'400'}}>To provide graduates with some degree of functional expertise, this course also provides an </div>
                <a class="LearnMoreCTA" href="/BBSPage">Learn More</a></a>
            </div>

        </div>

    </div>    
  )
}
