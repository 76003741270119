import React, { useEffect } from 'react'
import '../popup/style.css'
import image from '../popup/dummy.jpg'
import { useState } from 'react'
import { BASE_URL } from '../../Base'
import axios from 'axios'

export const Popup = (props) => {
 
 const closeHandler=()=>{
  props.popup(false)
 }
 console.log(props.display)
  return (
    <div class='popupBody' style={{ display:props.display}}>
      <div class='popup'>
        <img src={props.image} alt='' class='PopupImage' />
        <div class='closePopup' onClick={closeHandler}>
          Close
        </div>
      </div>
    </div>
  )
}
