import axios from 'axios'
import React, { useState } from 'react'
import '../../components/Style.css'

export const AdmissionForm = () => {
 const [data, setData] = useState({
 })
 const myRequest = (url) => {
   let response
   try {
     response = axios.post(url, null, null)
     alert("Submitted Successfully")

     //   headers: {
     //     'Content-Type': 'text/plain',
     //     'Accept': 'text/plain',
     //   },
     // })
   } catch (e) {
     response = e
   }
 }

 const handleChange = (e) => {
   e.preventDefault()
   const { name, value } = e.target
   setData({ ...data, [name]: value })
 }

 const handleSubmit = (e) => {
   e.preventDefault()
   const id = '1adNk6TdIgqNkpheIREFUvqSEmHo5-5d2T4UQf8FVR3o'
   const formUrl = 'https://docs.google.com/forms/d/' + id + '/formResponse'
   const queryString = require('query-string')
   const q = queryString.stringifyUrl({
     url: formUrl,
     query: data,
   })
   myRequest(q)
   setData({
     'entry.2085394520': '',
     'entry.942114543': '',
     'entry.843742282': '',
     'entry.1457127663': '',
     'entry.2063555604': '',
     'entry.313703180': '',
     'entry.1770750423': '',
   })
 }


  return (
    <div class='AdmissionContainer'>
      <div class='AboutDescriptionContainer2'>
        <div class='MainTitle'>Registration Form</div>
        <div class='LongDescription'>
          Student need to fill this online Registration form and will be
          Processed with commitment to School rules and regulation.
        </div>
      </div>

      <div class='formcontainer'>
        <form action='submit' onSubmit={handleSubmit}>
          <div class='AdmissionIputBox'>
            <label for=''>Full Name Of Student</label>
            <input
              placeholder='Enter Full Name Of Student'
              type='text'
              id='username'
              //   name='username'
              name='entry.2085394520'
              onChange={handleChange}
              value={data['entry.2085394520']}
              required
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Name of Parents</label>
            <input
              placeholder='Enter Name of Parents'
              type='text'
              id='parent_name'
              //   name='username'
              name='entry.942114543'
              onChange={handleChange}
              value={data['entry.942114543']}
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Email Address</label>
            <input
              placeholder='Enter Email Address'
              type='email'
              id='email'
              //   name='username'
              name='entry.843742282'
              onChange={handleChange}
              value={data['entry.843742282']}
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Contact Number (Mobile Number)</label>
            <input
              placeholder='Contact Number (Mobile Number)'
              type='tel'
              id='mobileNumber'
              //   name='username'
              name='entry.1457127663'
              onChange={handleChange}
              value={data['entry.1457127663']}
              required
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Parent Contact Number (Mobile Number)</label>
            <input
              placeholder='Enter your Parent Contact Number'
              type='tel'
              id='parentContact'
              //   name='username'
              name='entry.2063555604'
              onChange={handleChange}
              value={data['entry.2063555604']}
              required
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Name of school</label>
            <input
              placeholder='Enter Name of school'
              type='text'
              id='schoolname'
              //   name='username'
              name='entry.313703180'
              onChange={handleChange}
              value={data['entry.313703180']}
              required
            />
          </div>

          <div class='AdmissionIputBox'>
            <label for=''>Select one of the Program</label>
            <select
              placeholder='Select one of the Program'
              type='text'
              id='program'
              //   name='username'
              name='entry.1770750423'
              onChange={handleChange}
              value={data['entry.1770750423']}
              required
            >
              <option value='Science' selected>
                Select One program
              </option>
              <option value='Science'>+2 Science</option>
              <option value='Science'>
                +2 Management with Computer science
              </option>
              <option value='Science'>
                +2 Management with Hotel Management
              </option>
              <option value='Science'>
                +2 Management with Business Studies
              </option>
              <option value='Science'>
                +2 Management with Business Mathematics
              </option>
              <option value='Science'>+2 Management with Economics</option>
              <option value='Science'>Humanities</option>
              <option value='Science'>Special Law</option>
            </select>
          </div>

          <input class='FormSubmit' type='submit' value='Apply Now' />
        </form>
      </div>
    </div>
  )
}
