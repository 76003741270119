import axios from 'axios'
import React, { useState } from 'react'
import '../../components/Style.css'

export const RegistrationOpen = () => {
  return(
    <div className="RegBg">
      {/* <div className="Regtext" style={{ marginBottom:'3px'}}>Click on the button below to register.</div> */}
      {/* <div className="RegBtn">Register Now</div> */}
      <a class='Btn1' style={{ margin:'0px'}} href='/ApplyNow'>Click Here To Apply Now</a>
    </div>
)
}
