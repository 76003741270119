import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { HumanitiesDetails } from '../../components/HumanitiesDetails/HumanitiesDetails'

const HumanitiesPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <HumanitiesDetails/>
      <Footer/>
    </div>
  )
}

export default HumanitiesPage