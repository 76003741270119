import React from 'react'
import { NavBar } from '../../components/NavBar/NavBar'
import { Footer } from '../../components/Footer/Footer'
import '../../components/Style.css'
import { ManagementDetailContainer } from '../../components/ManagementDetailContainer/ManagementDetailContainer'

const ManagementPage = () => {
  return (
    <div className="pageContainer">
      <NavBar/>
      <ManagementDetailContainer/>
      <Footer/>
    </div>
  )
}

export default ManagementPage