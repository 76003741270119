import React from 'react'
import '../../components/Style.css'
import BodImg7 from '../../assets/Bod7.png'
import SC1 from '../../assets/SC1.png'
import SC2 from '../../assets/SC2.png'
import SC3 from '../../assets/Sc3.png'
import SC4 from '../../assets/SC4.png'
import MGH from '../../assets/MGHEAD.png'



export const HumanitiesDetails = () => {
return (
    <div className="DetailContainer">
     <div className="DetailIntroContainer">
            <div className="DetailIntro">
                  <div className="DetailTitle">Humanities Stream</div>
                  <div class="DetailPageDescription">Humanities students had limited scope in career. Now, humanities stream has a wider horizon than any other subjects that give you a plethora of career options in various sectors. This stream gives an immense insight about human society, its reality, customs, cultures, traditions and so on. 
Excellence in anything starts with a vision, a passion and a will. The Department of Humanities TSA run with the aegis of highly acclaimed stalwarts has been a covetous oasis in the academic corridors of higher education. Thus, with a proud tradition of its two decade long academic odyssey, TSA offers Humanities courses too. We believe that this course will be a pathway to finding your aspiration. 
Get enrolled at TSA in Humanities and bolster your feted and rewarding career!  </div>
            </div>
      </div>

    
      <div class="subjectSlider">
              <br/>
                  <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                          <div class="carousel-inner">
                          <div class="carousel-item active">
                              <img class="d-block w-100" src={SC1} alt="First slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC2} alt="Second slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC3} alt="Third slide"/>
                          </div>
                          <div class="carousel-item">
                              <img class="d-block w-100" src={SC4} alt="Third slide"/>
                          </div>

                          
                        </div>

                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>

                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                  </div>
              <br/>
      </div> 

      <div className="imagescroll">
            <img className="DetailImage" src={SC1} srcset="" />
            <img className="DetailImage" src={SC2} srcset="" />
            <img className="DetailImage" src={SC3} srcset="" />
            <img className="DetailImage" src={SC4} srcset="" />
      </div> 

      <div class="DetailPageDescription2">Takshashila Academy follows the course of study set by the National Examination Board (NEB). The courses are the following:</div>
      <div className="stream">
              <div class="DetailPageDescription2">
              <b>Class XI : Humanities Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory Nepali <br/>
              3. Social<br/>
              3. Optional Subjects (Any Three) <br/>
              (i) Major English <br/>
              (ii) Mass Communication <br/>
              (iii) Sociology <br/>
              (iv) Economics <br/>
              (v) Mathematics<br/>
              (vi) Rural Development<br/>
              </div>

              <div class="DetailPageDescription2">
              <b>Class XI : Humanities Stream</b><br/>
              1. Compulsory English <br/>
              2. Compulsory Nepali <br/>
              3. Social<br/>
              3. Optional Subjects (Any Three) <br/>
              (i) Major English <br/>
              (ii) Mass Communication <br/>
              (iii) Sociology <br/>
              (iv) Economics <br/>
              (v) Mathematics<br/>
              (vi) Rural Development<br/>
              </div>
      </div>



      <div  id="4" className="SingleStaffMsg">
                <div className="IntroImage">
                    <div className="singleIntro">
                        <div class="MsgNameHolder" style={{ 'color':'#000000'}}>Mr. JP Sitaula - Program Director</div>
                        <div class="SingleMsgTitle">“With the glorious name Takshashila Academy, we are moving forward with a sense of determination to build an educational institution of excellence.”</div>
                        <div class="SingleMsgDescription" >High quality is our goal. We do not want to compromise on it. We are confident that with our sincere efforts to teach, students will definitely learn something good for their life. Already there have been our students in medical sciences, engineering, business enterprises and humanities as illustrious examples. I would like to urge the parents to bring their children to Takshashila for their education with a good amount of moral content and emphasis on the strength of character in addition to the quality of education.  I am sure the society around us will feel proud that there is such an institution like ours.</div>
                    </div>
                    <img class="SingleMsgImage" src={MGH} alt=""/>
                </div>
                {/* <div class="SingleMsgLongDescription">to nurture the future for every family we serve. I believe that when you read our prospectus, you will be impressed by our desire to nurture; to care for, encourage and support your children. I hope, however, you will also be struck by our determination to develop their gifts to help them achieve their full potential. We aim to provide a holistic learning experience by encouraging individuality and interacting with our community. Please enjoy learning more about our institution, through this prospectus. I hope you will give Takshashila, the opportunity to prepare your child for a happy and successful future.</div> */}
                {/* <div className="singleMsgdivider"></div> */}
      </div>


    </div>
  )
}
