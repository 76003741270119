import React from 'react'
import '../../components/Style.css'

export const TextSlider = () => {
  return (
    <div class="MotoContainer">
    " Takshashila Academy For High Quality Education "
    </div>
  )
}

