export const convertMonth = (month) => {
  switch (month) {
    case '01':
      return 'Jan'
      break
    case '02':
      return 'Feb'
      break
    case '03':
      return 'Mar'

    case '04':
      return 'Apr'

    case '05':
      return 'May'

    case '06':
      return 'Jun'

    case '07':
      return 'Jul'

    case '08':
      return 'Aug'

    case '09':
      return 'Sep'

    case '10':
      return 'Oct'

    case '11':
      return 'Nov'

    case '12':
      return 'Dec'
  }
}
