import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`
const Image = styled.img`
  
 
  
`

export const SingleNotice = () => {
  const noticeSrc = useSelector((state) => state.slider.activeImage)
  console.log(noticeSrc)
 
  return (
    <Container>
      <Image src={noticeSrc}/>
    </Container>
  )
}
