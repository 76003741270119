import React from 'react'
import '../../components/Style.css'

export const Mission = () => {
  return (
    <div class="VContainer">
        <div class="InfoWrapper">
            <div class="InfoTitle" style={{color:"#89CC59"}}>Vision</div>
            <div class="InfoDescription">To produce globally competitive students with high quality education.</div>
        </div>

        <div class="InfoDivider"></div>

        <div class="InfoWrapper">
            <div class="InfoTitle" style={{color:"#F7DE42"}}>Mission</div>
            <div class="InfoDescription">To produce academically sound, socially responsible and morally upright citizens not only for Nepal but also for the whole world.</div>
        </div>

        <div class="InfoDivider"></div>

        <div class="InfoWrapper">
            <div class="InfoTitle" style={{color:"#9DCBED"}}>Objective</div>
            <div class="InfoDescription">To develop the virtues of self-discipline, self-reliance and self confidence  to be adaptable for the challenges of 21th century.</div>
        </div>
    </div>
  )
}
