import React from 'react'
import '../../components/Style.css'
import BodImg1 from '../../assets/Bod1.png'
import BodImg2 from '../../assets/Bod2.png'
import BodImg3 from '../../assets/Bod3.png'
import BodImg4 from '../../assets/Bod4.png'
import BodImg5 from '../../assets/Bod5.png'
import BodImg6 from '../../assets/Bod6.png'
import BodImg7 from '../../assets/Bod7.png'
import BodImg8 from '../../assets/Bod8.png'
import BodImg9 from '../../assets/Bod9.png'
import BodImg10 from '../../assets/Bod10.png'
import BodImg11 from '../../assets/Bod11.png'


export const Bod = () => {
  return (
    <div class="BodContainer">
      <div class="MainTitle">Board Of Directors</div>
        <div class="BodCollection">
            <div class="BodItem">
                <img src={BodImg1} alt="" srcset="" />
                <div class="BodName">Mr. Rajendra Man Shrestha</div>
                <div class="BodPosition">Chairman</div>
                {/* <div class="BodExp">(  Lecturer - 20 Yrs  )</div> */}
            </div>

            <div class="BodItem">
                <img src={BodImg2} alt="" srcset="" />
                <div class="BodName">Mr. Bharat Prasad Aryal</div>
                <div class="BodPosition">Chief Admin. Advisor</div>
                <div class="BodExp">(  Lecturer - 20 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg3} alt="" srcset="" />
                <div class="BodName">Dr. Bishan Datt Bhatt</div>
                <div class="BodPosition">Chief Admin. Advisor</div>
                <div class="BodExp">(  Lecturer - 28 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg4} alt="" srcset="" />
                <div class="BodName">Mr. Sunil Kumar Singh</div>
                {/* <div class="BodPosition">Chairman</div> */}
                <div class="BodExp">(  Asso. Prof. - 36 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg5} alt="" srcset="" />
                <div class="BodName">Mr. Jayanta Acharya</div>
                {/* <div class="BodPosition">Chairman</div> */}
                <div class="BodExp">(  Asso. Prof. - 23 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img  src={BodImg6} alt="" srcset="" />
                <div class="BodName">Mr. J.P. Sitaula</div>
                <div class="BodPosition">Program Diector (Morning)</div>
                <div class="BodExp">(  Lecturer - 19 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg7} alt="" srcset="" />
                <div class="BodName">Mr. Naresh Prasad Bhatt</div>
                <div class="BodPosition">Program Director (Day)</div>
                <div class="BodExp">(  Lecturer - 18 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img  src={BodImg8} alt="" srcset="" />
                <div class="BodName">Mr. Ramesh Kumar Paudel</div>
                {/* <div class="BodPosition">Chairman</div> */}
                <div class="BodExp">(  Lecturer - 21 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg9} alt="" srcset="" />
                <div class="BodName">Mr. Rajeshwor Yadav</div>
                {/* <div class="BodPosition"></div> */}
                <div class="BodExp">(Founder Green Hills Sec. School)</div>
            </div>

            <div class="BodItem">
                <img src={BodImg10} alt="" srcset="" />
                <div class="BodName">Mr. Hari Dhakal</div>
                {/* <div class="BodPosition">Chairman</div> */}
                <div class="BodExp">(  Mgmt. - 18 Yrs  )</div>
            </div>

            <div class="BodItem">
                <img src={BodImg11} alt="" srcset="" />
                <div class="BodName">Mr. Netra Pd. Neupane</div>
                <div class="BodPosition">Principal</div>
                <div class="BodExp">(  Lecturer - 19 Yrs  )</div>
            </div>           

        </div>
    </div>


  ) 
}
