import React, { useEffect, useState } from 'react'
import '../../components/Style.css'
import NoticeImage from '../../assets/notice.png'
import axios from 'axios'
import { convertMonth } from '../../utils/convertDate'
import { Link } from 'react-feather'
import { useDispatch } from 'react-redux'
import { setActiveImage } from '../../redux/sliderRedux'
import { BASE_URL } from '../../Base'
export const Notice = () => {
    const [activeNotice,setActiveNotice] = useState({title:'',
  img:''});
    const [notice,setNotice] = useState([])
    const [Items, setItems] = useState([])
    const [Img,setImg]=useState([])
    const [ActiveImg,setActiveImg] =useState('')
    useEffect(() => {
      const getNotices = async () => {
        try {
          const res = await axios.get(
            BASE_URL + '/wp-json/wp/v2/notice?acf_format=standard'
          )
          setNotice(res.data)
          // setItems(res.data)
          setActiveNotice({
            title:res.data[0].acf.title,
            img:res.data[0].acf.image
          })
        } catch (err) {}
      }
      getNotices()
    }, [])
    // useEffect(()=>{
    //   const getImages = async () => {
    //     try {
    //       const res = await axios.get(activeNotice.img)
    //       setImg(res.data)
    //    } catch (err) {}
    //   }
    //     getImages()
    // },[activeNotice])
    // useEffect(()=>{
    //   console.log(Img)
    //     Img&&Img.map((key, pos) => {
    //       pos == 0 && setActiveImg(key.source_url)
    //     })
    // },[Img])
    const dispatch = useDispatch()
  return (
    <div class='noticePageContainer'>
      <div class='NoticeWrapper'>
        <div class='Notice'>
          <div class='MainTitle'>All NOTICES</div>
          <div class='NoticeContainer'>
            {notice.map((item, pos) => (
              <div
                class='Noticewrrapper'
                onClick={() => {
                  setActiveNotice({
                    title: item.acf.title,
                    img:item.acf.image,
                  })
                }}
              >
                <div
                  class='NoticeBullet'
                  style={{
                    animation: `${
                      pos == 0 ? 'blinker 1s step-start infinite' : 'none'
                    }`,
                  }}
                ></div>
                <a
                  class='NoticeTitle'
                  style={{
                    color: `${pos == 0 ? '#89CC59' : 'none'}`,
                    animation: `${
                      pos == 0 ? 'blinker 1s step-start infinite' : 'none'
                    }`,
                  }}
                >
                  {item.acf.title}
                </a>
                <div
                  class='NoticeDate'
                  style={{
                    color: `${pos == 0 ? '#89CC59' : 'none'}`,
                    animation: `${
                      pos == 0 ? 'blinker 1s step-start infinite' : 'none'
                    }`,
                  }}
                >
                  {convertMonth(item.date.substring(0, 10).split('-')[1]) +
                    ' ' +
                    item.date.substring(0, 10).split('-')[2] +
                    ' ' +
                    item.date.substring(0, 10).split('-')[0]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class='ActiveNoticeWrapper'>
        <a class='NoticeTitle' style={{ color: '#89CC59' }}>
          {activeNotice.title}
        </a>
        <a
          href='/notice'
          target='_blank'
        >
          <img
            class='NoticeImageActive'
            src={
              activeNotice.img
            }
            alt=''
            onClick={()=>{
              dispatch(setActiveImage(activeNotice.img))
            }}
          />
        </a>
      </div>
    </div>
  )
}