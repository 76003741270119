import React from 'react'
import '../../components/Style.css'

export const TakMean = () => {
  return (
    <div class="AboutDescriptionContainer2">
        <div class="MainTitle" style={{ 'paddingLeft':'20px'}}>What Does Takshashila Mean ?</div>
        <div class="LongDescription">According to Monier-Williams Sanskrit- English Dictionary, Taksha means to form by cu  ng, plane , chisel and chop used in Rig –Veda. It also means to form the mind, invent or to fashion out of wood or stone, to make or create. Shila means stone. All those meanings are applicable in our context. Here we have presented a volcanic rock on one side and a stone image of Buddha made from it on the other side, implying that educa  on is a process that transforms something formless into a beau  full form. Takshashilla Academy is located near the ring road at Samakhusi, Kathmandu. Its beau  ful surrounding and easy accessibility a  ract visitors. A nice view of mountains including the Himalayas makes it more beautiful. The office and +2 building are just opposite to Radhaswami Satsang Building.</div>
    </div>
  )
}
